import log from 'lib/util/log';

let currentLocaleGetter;

export function getAllLocales() {
	return process.env.LOCALES;
}

export function getCurrentLocale() {
	try {
		return currentLocaleGetter();
	} catch (e) {
		log.warn('No currentLocaleGetter set');
	}

	return false;
}

export function setCurrentLocaleGetter(callback) {
	currentLocaleGetter = callback;
}
