import React from 'react';
import PropTypes from 'prop-types';
import log from 'lib/util/log';
import withCurrentLocale from './withCurrentLocale';
import { translate } from './util';

/**
 * Wrapping component that applies translated properties to things like INPUT placeholder attributes.
 * @param {number} lid the id of the string
 * @param {string} attribute the attribute to inject to the single child
 * @return {jsx} the jsx of itself and passed children
 */
const InjectLocalizedAttributeBase = ({ attribute, lid, variables, postFn, children, currentLocaleData }) => {
	if (React.Children.count(children) !== 1) {
		log.warn('You may only pass one child element to InjectLocalizedAttribute.');

			// return the original children (wrapped so we don't explode)
		return <span>{children}</span>;
	}

	let translatedString;

	try {
		translatedString = translate(currentLocaleData, lid, variables, postFn);
	} catch (e) {
			// return the original child
		return children;
	}

		// return a clone of the child with our localized attribute
	return React.cloneElement(children, {
		[attribute]: translatedString,
	});
};

InjectLocalizedAttributeBase.propTypes = {
	lid: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
	variables: PropTypes.oneOfType([
		PropTypes.array,
		PropTypes.object,
	]),
	postFn: PropTypes.func,
	attribute: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired,
};

const InjectLocalizedAttribute = withCurrentLocale(InjectLocalizedAttributeBase);
InjectLocalizedAttribute.displayName = 'InjectLocalizedAttribute';

export default InjectLocalizedAttribute;
