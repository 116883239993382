import { createSubject } from 'light-observable/observable/subject';

/**
 * A wrapper around a light-observable "subject" that adds a get/settable "value" property.
 */
class BehaviorSubject {
	_value = undefined;
	_error = undefined;
	_closed = undefined;
	_observable = undefined;
	_sink = undefined;

	constructor(initialState) {
		// createSubject will only set the subject "mode" to Behavior if the initial state is non-undefined
		if (initialState === undefined) {
			throw TypeError(
				'Initial state of a BehaviorSubject cannot be undefined. Consider null, false, or another value instead.',
			);
		}

		const [observable, sink] = createSubject({ initial: initialState });
		this._observable = observable;
		this._sink = sink;
		this._value = initialState;
	}

	_requireOpenStream() {
		if (this.closed) {
			throw new Error('Invalid action on a closed BehaviorState');
		}
	}

	next(val) {
		this._requireOpenStream();
		this._value = val;
		this._sink.next(val);
	}

	error(err) {
		this._requireOpenStream();
		this._closed = true;
		this._error = err || true;
		// An error will be thrown from any observers that have no error handler, so this step must be taken last.
		this._sink.error(err);
	}

	complete() {
		this._requireOpenStream();
		this._sink.complete();
		this._closed = true;
	}

	get open() {
		return !this._closed;
	}

	get closed() {
		return this._closed;
	}

	get errorState() {
		return this._error || false;
	}

	get observable() {
		return this._observable;
	}

	set value(val) {
		this.next(val);
	}

	get value() {
		return this._value;
	}
}

export default BehaviorSubject;
