import PropTypes from 'prop-types';
import { translate } from './util';
import withCurrentLocale from './withCurrentLocale';

/**
 * Provide localized strings from LIDs as parameters to a rendering function.
 * @param {object} lidMap Object mapping keys to LIDs, or to an object consisting of { lid, variables }
 * @param {function} render Function to render the contents. Receives an object matching lidMap with translated strings.
 * @param {function} postFn Function to post-process the results of the translation.
 */
const ProvideLocalizedStringsBase = ({
	currentLocaleData,
	lidMap,
	render: renderFunction,
	postFn,
	reportMissingLid,
}) => {
	const translated = {};

	Object.keys(lidMap).forEach(key => {
		let lid = lidMap[key];
		let variables = {};

		if (typeof lid === 'object' && lid.lid) {
			variables = lid.variables;
			lid = lid.lid;
		}

		try {
			translated[key] = translate(currentLocaleData, lid, variables, postFn);
		} catch (error) {
			if (error.type === 'MISSING_LID') {
				reportMissingLid(lid, error);
			}
			translated[key] = lid;
		}
	}, {});

	return renderFunction(translated);
};

ProvideLocalizedStringsBase.propTypes = {
	lidMap: PropTypes.object,
	render: PropTypes.func.isRequired,
};

const ProvideLocalizedStrings = withCurrentLocale(ProvideLocalizedStringsBase);
ProvideLocalizedStrings.displayName = 'ProvideLocalizedStrings';

export default ProvideLocalizedStrings;
