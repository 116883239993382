// This file works around this preact issue:
//     https://github.com/developit/preact-compat/issues/501
// This file can be removed when the issue is fixed in preact
// TODO: check occasionally

import { createElement as h, Component } from 'react';
import { unmountComponentAtNode, unstable_renderSubtreeIntoContainer as renderSubtreeIntoContainer } from 'react-dom';

class Portal extends Component {
	componentWillUnmount() {
		unmountComponentAtNode(this.props.container);
	}
	componentDidMount() {
		renderSubtreeIntoContainer(this, this.props.vnode, this.props.container);
	}
	render() {
		// render nothing at original location
		return null;
	}
}

export function createPortal(vnode, container) {
	return h(Portal, { vnode, container });
}
