import log from 'lib/util/log';

/**
 * Convert a text string to the HTML necessary to display that string. Creates entites
 * for reserved characters, and does not parse tags or entities.
 * @param {string} text Text to convert.
 * @returns {string} Resulting HTML string.
 */
const getHtmlFromText = text => {
	const node = global.document.createElement('div');
	node.textContent = text;
	return node.innerHTML;
};

/**
 * Sanitize variables for inclusion into an HTML string.
 * @param {array|object} variables Variables.
 * @param {array} except Variables (keys) to pass through without sanitization.
 * @returns {array|object} Variables with HTML entities converted.
 */
const sanitizeVariables = (variables: Array | Object, except?: Array) => {
	const isArray = Array.isArray(variables);
	const varsOut = isArray ? [] : {};
	Object.keys(variables).forEach(k => {
		// In the case of an array, we need to make k a number and add 1 for 1-indexing.
		const isException = except && except.indexOf(isArray ? Number(k) + 1 : k) !== -1;
		varsOut[k] = isException ? variables[k] : getHtmlFromText(variables[k]);
	});
	return varsOut;
};

/**
 * Get the translation text for a LID. Uses the currentLocaleStream global setting for locale and messages.
 * If the LID key is a string starting with an underscore, that meta-data LID is returned (used for language's _name and _code).
 * If the LID key is any other string, it is rendered with "[!LID]" and a warning is output.
 * @param {number|string} lid LID key.
 * @returns {string} Returns the LID template without variables expanded.
 */
const getMessage = (lid: number | string, messages, locale) => {
	// check if placeholder string
	if (typeof lid === 'string') {
		// Support _name and _code
		if (lid.charAt(0) === '_') {
			lid = lid.slice(1);
		} else {
			log.warn(`[HUI][i18n] Showing lid placeholder '${lid} [!LID]'.`);
			return `${lid} [!LID]`;
		}
	}

	// check if lid available
	if (messages[lid] === undefined) {
		const error = new Error(`MISSING LID: ${lid} for locale ${locale}`);
		error.type = 'MISSING_LID';
		throw error;
	}

	return messages[lid];
};

/**
 * Internal-use function to perform a translation given locale data. Pulls from messages, interpolates vars.
 *
 * @param  {object} messages  [description]
 * @param  {number|string} lid       [description]
 * @param  {object|array} variables [description]
 * @return {string}           the final string, or empty string
 */
const translate = (
	{ messages, locale },
	lid: number | string,
	variables: Array | Object = [],
	postFn = text => text,
) => {
	let variablesLocal = variables;
	const message = getMessage(lid, messages, locale);

	// because message.txt lids are 1-based by convention
	// we are essentially adding an empty string entry to a copy of variables
	// if it's not an array, there's no need to actuall make a copy
	if (Array.isArray(variables) && variables.length) {
		variablesLocal = [''].concat(variables);
	}

	return postFn(
		Object.entries(variablesLocal).reduce((text, [key, value]) => {
			if (key === '0') return text;

			if (text.indexOf(`__${key}__`) === -1) {
				log.warn(`Cannot find key '__${key}__' in lid ${lid}`);
			}

			// we split with a string instead of a global regex, because
			// key could theoretically contain regex characters
			return text.split(`__${key}__`).join(value);
		}, message || ''),
	);
};

export { sanitizeVariables, translate };
