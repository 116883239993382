// @flow

import BehaviorSubject from 'lib/util/BehaviorSubject';
import { compose, mapPropsStream } from 'recompose';
import { from, combineLatest } from 'light-observable/observable';
import { map } from 'light-observable/operators';

class ConfigStore extends BehaviorSubject {
	// constructor exists to give a default initialState
	constructor(initialState: Object = {}) {
		super(initialState);
		this.set(initialState);
	}

	set(additionalState: Object, callback: Function = () => {}): Promise {
		// Create property definitions with getters for any new properties introduced
		const newProperties = Object.keys(additionalState)
			// Ignore existing properties
			.filter(k => !(k in this))
			// Make getters that run the "get" method (to pull the values from this.value)
			.reduce((propMap, k) => {
				propMap[k] = {
					get: this.get.bind(this, k),
				};
				return propMap;
			}, {});
		// Apply that property definition to this ConfigStore instance
		Object.defineProperties(this, newProperties);

		// Store the actual value in .value
		this.value = {
			...this.value,
			...additionalState,
		};

		return Promise.resolve().then(() => {
			return this.value;
			callback(this.value);
		});
	}

	get(property: String) {
		return this.property || this.value[property];
	}

	subscribe(...args: any): Object {
		return this.observable.subscribe(...args);
	}

	getState(): Object {
		return this.value;
	}
}

const withConfigStore = (store: ConfigStore = new ConfigStore({ __throwaway: 'yes' })) =>
	compose(
		mapPropsStream(props$ =>
			combineLatest(from(props$), store.observable).pipe(map(([props, config]) => ({ ...props, ...config })))
		)
	);

export default ConfigStore;
export { withConfigStore };
