import LocalizedString from './i18n/LocalizedString';
import LocalizedImgAlt from './i18n/LocalizedImgAlt';
import InjectLocalizedAttribute from './i18n/InjectLocalizedAttribute';
import ProvideLocalizedStrings from './i18n/ProvideLocalizedStrings';
import translateLid from './i18n/translateLid';
import localeStream, { localeObservable, addMessagesLoader, setLocale } from './i18n/localeStream';
import LocalizationStream from './i18n/LocalizationStream';
import withCurrentLocale from './i18n/withCurrentLocale';
import { getCurrentLocale, getAllLocales, setCurrentLocaleGetter } from './i18n/locales';
import Events from 'lib/events';

// listen to CHUI.API.setLocale
const apiMethods = {
	setLocale: ({ locale }) => setLocale(locale),
	getAllLocales: () => getAllLocales(),
	getCurrentLocale: () => getCurrentLocale(),
	setCurrentLocaleGetter: () => setCurrentLocaleGetter(),
};
Events.addEventListener('_apiCallI18n', event => {
	if (apiMethods[event.name]) {
		apiMethods[event.name].call(null, event.args);
	}
});

export {
	LocalizedString,
	LocalizedImgAlt,
	InjectLocalizedAttribute,
	ProvideLocalizedStrings,
	translateLid,
	LocalizationStream,
	localeStream,
	localeObservable,
	addMessagesLoader,
	withCurrentLocale,
	setLocale,
	getAllLocales,
	getCurrentLocale,
	setCurrentLocaleGetter,
};

export default {
	LocalizedString,
	LocalizedImgAlt,
	InjectLocalizedAttribute,
	ProvideLocalizedStrings,
	translateLid,
	addMessagesLoader,
	setLocale,
	getAllLocales,
	getCurrentLocale,
	setCurrentLocaleGetter,
};
