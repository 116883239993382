// @flow

// this file is intentionally light

// we will export these
import API from 'lib/api';
import Config from 'lib/config';
import Events from 'lib/events';
import Auth from 'lib/auth';

// this is our own setup
import i18n, { setCurrentLocaleGetter } from 'lib/i18n';
import HUILoader from 'lib/i18n/HUILoader';
import Cookie from 'js-cookie';
i18n.addMessagesLoader(HUILoader);

// call initial setLocale with setImmediate to give all messagesLoaders a chance to be bound
setImmediate(() => i18n.setLocale(Cookie.get('CLOUD_LOCALE') || 'en_US'));
setCurrentLocaleGetter(() => Cookie.get('CLOUD_LOCALE') || 'en_US');

// we want these in our initial bundle (rather than the lazy chunks)
import MenuPopover from 'lib/components/MenuPopover';

// dummy var, instances of serverEnv.* will be string-replaced in an aws lambda
// instances of process.env.BCC_URL will be string-replaced at build time
const serverEnv = {};
Config.set({
	BCC_URL: serverEnv.BCC_URL || process.env.BCC_URL,
	BCC_SOAP_API_URL: serverEnv.BCC_SOAP_API_URL || process.env.BCC_SOAP_API_URL,
	BCC_API_URL: serverEnv.BCC_API_URL || process.env.BCC_API_URL,
	COOKIE_DOMAIN: serverEnv.COOKIE_DOMAIN || process.env.COOKIE_DOMAIN,
	BCC_FOOTER_PRIVACY_URL: serverEnv.BCC_FOOTER_PRIVACY_URL || process.env.BCC_FOOTER_PRIVACY_URL,
	BCC_FOOTER_TERMS_URL: serverEnv.BCC_FOOTER_TERMS_URL || process.env.BCC_FOOTER_TERMS_URL,
	BCC_HEADER_COMMUNITY_LINK_URL: serverEnv.BCC_HEADER_COMMUNITY_LINK_URL || process.env.BCC_HEADER_COMMUNITY_LINK_URL,
	BCC_HEADER_SUPPORT_LINK_URL: serverEnv.BCC_HEADER_SUPPORT_LINK_URL || process.env.BCC_HEADER_SUPPORT_LINK_URL,
	BCC_HEADER_SETTINGS_LINK_URL: serverEnv.BCC_HEADER_SETTINGS_LINK_URL || process.env.BCC_HEADER_SETTINGS_LINK_URL,
	BCC_HEADER_DOWNLOADS_LINK_URL: serverEnv.BCC_HEADER_DOWNLOADS_LINK_URL || process.env.BCC_HEADER_DOWNLOADS_LINK_URL,
	BCC_USERMENU_PROFILE_LINK_URL: serverEnv.BCC_USERMENU_PROFILE_LINK_URL || process.env.BCC_USERMENU_PROFILE_LINK_URL,
	BCC_USERMENU_LANGUAGE_LINK_URL:
		serverEnv.BCC_USERMENU_LANGUAGE_LINK_URL || process.env.BCC_USERMENU_LANGUAGE_LINK_URL,
	BCC_USERMENU_SIGNOUT_LINK_URL: serverEnv.BCC_USERMENU_SIGNOUT_LINK_URL || process.env.BCC_USERMENU_SIGNOUT_LINK_URL,
	BCC_USERMENU_SIGNIN_LINK_URL: serverEnv.BCC_USERMENU_SIGNIN_LINK_URL || process.env.BCC_USERMENU_SIGNIN_LINK_URL,
});

// so we know where to pull JS chunks from
__webpack_public_path__ = serverEnv.PUBLIC_ASSET_PATH || process.env.PUBLIC_ASSET_PATH;

export { API, Config, Events, Auth };
export default {
	API,
	Config,
	Events,
	Auth,
};
