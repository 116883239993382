/**
 * Pass-through module exposing an publish-ready object for use externally.
 */

import { withAuth, observe } from 'lib/observables/auth';
import { observable as currentAccountObservable } from 'lib/observables/currentAccount';
import Events from 'lib/events';

currentAccountObservable.subscribe(current => {
	if (!current.loading && !current.error) {
		Events.trigger('accountSelectionChange', {
			accountId: Number(current.id),
			allAccounts: Boolean(current.allAccounts),
		});
	}
});

export default { withAuth, observe };
