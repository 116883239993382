/**
 * Attempt an array of Promises, but unlike Promise.all, complete all of them and return a status object for each,
 * indiciating success and result, or error. Yeilds an array of { success, result } or { error } objects
 * once all promises are resolved.
 * @param {array<Promises>} promises Array of Promises to attempt to resolve
 * @param {string} successKey Property name of the success indicator in the resulting object
 * @param {string} errorKey Property name of the error in the resulting object
 * @param {string} resultKey Property name of the results property in the resulting object
 * @returns {array<object>} Array of result objects
 */
export default function resolveAllPromises(promises, successKey = 'success', errorKey = 'error', resultKey = 'result') {
	const resolvers = promises.map(p =>
		Promise.resolve(p)
			.then(results => ({ [successKey]: true, [resultKey]: results }))
			.catch(error => ({ [errorKey]: error })),
	);
	return Promise.all(resolvers);
}
