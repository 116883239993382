import { compose, mapPropsStream, setDisplayName } from 'recompose';
import { from, combineLatest } from 'light-observable/observable';
import { map, switchMap } from 'light-observable/operators';
import { combineArrayOfObjectsFunction } from 'lib/util/combine';
import { fetchDedupe } from 'fetch-dedupe';
import Config from 'lib/config';
import { DEFAULT_BCC_SERVICE_ID } from 'lib/constants';
import parseXML from 'lib/util/parseXML';
import APISubject from 'lib/util/APISubject';
import type { PropsWithStatus, BCCIsAuthenticatedResponse } from 'lib/types';

class IsAuthenticatedSubject extends APISubject {
	_serviceId = DEFAULT_BCC_SERVICE_ID;

	_shouldUpdate(params = {}) {
		const { serviceId } = params;
		return (serviceId && serviceId != this._serviceId) || super._shouldUpdate(params);
	}

	update(params = {}) {
		const { serviceId } = params;
		this._serviceId = serviceId || this._serviceId;
		return super.update(params);
	}

	_fetch({ serviceId: newServiceId }) {
		const sessionToken = Config.SESSION_TOKEN || '';
		const serviceId = newServiceId || this._serviceId;

		return (
			fetchDedupe(
				Config.BCC_SOAP_API_URL,
				{
					method: 'post',
					mode: 'cors',
					credentials: 'include',
					headers: {
						'Content-Type': 'text/xml; charset=utf-8',
						SOAPAction: 'isAuthenticated',
					},
					body: `
				<?xml version="1.0" encoding="utf-8"?>
				<x:Envelope xmlns:x="http://schemas.xmlsoap.org/soap/envelope/" xmlns:urn="urn:BarracudaCloudAPI">
					<x:Header/>
					<x:Body>
						<urn:isAuthenticatedRequest>
							<urn:auth_token>${sessionToken}</urn:auth_token>
							<urn:service>${serviceId}</urn:service>
						</urn:isAuthenticatedRequest>
					</x:Body>
				</x:Envelope>
			`.trim(),
				},
				{
					responseType: 'text',
				},
			)
				.then(({ data: xml }) => parseXML(xml))
				.then(
					result =>
						result['SOAP-ENV:Envelope']['SOAP-ENV:Body']['ns1:isAuthenticatedResponse'] || {
							isAuthenticatedError: true,
						},
				)
				// postprocess the result
				.then(result => {
					// if there is only one account in accounts, it doesn't get made into an array properly
					if (typeof result.accounts === 'object' && result.accounts.id && result.accounts.name) {
						result.accounts = [result.accounts];
					}
					return result;
				})
		);
	}
}

export const subject = new IsAuthenticatedSubject();
export const observable = subject.observable;
export const observe = subject.observe.bind(subject);
export const update = subject.update.bind(subject);

export const withIsAuthenticated = compose(
	setDisplayName('withIsAuthenticated(AccountSwitcher)'),
	mapPropsStream(props$ => {
		props$ = from(props$);
		return combineLatest(props$, props$.pipe(switchMap(({ serviceId }) => observe({ serviceId })))).pipe(
			map(combineArrayOfObjectsFunction([undefined, 'isAuthenticated'])),
		);
	}),
);
