import baseMessages from 'lang/en_US/messages.txt';
import { BASE_MESSAGES_LOCALE, QA_LOCALE } from '../constants.js';

function HUILoader(locale) {
	if (locale === BASE_MESSAGES_LOCALE) {
		return Promise.resolve(baseMessages);
	}

	return import(/* webpackInclude: /messages\.txt$/ */
	// Do not lazy-load the base language:
	/* webpackExclude: /en_US\/messages\.txt$/ */
	/* webpackMode: "lazy" */
	/* webpackChunkName: "locale/[request]" */
	`lang/${locale}/messages.txt`);
}

export default HUILoader;
