import BehaviorSubject from 'lib/util/BehaviorSubject';

class APISubject {
	/**
	 * Default value prior to receiving the initial event.
	 */
	_preInitValue = { preInit: true, loading: true, error: null };

	/**
	 * Whether or not to initialize upon instance construction.
	 */
	_initOnConstruct = false;

	/**
	 * Perform an initial request with the given parameters.
	 * @param {*} params Parameters
	 */
	constructor(params, initialValue) {
		this._subject = new BehaviorSubject(initialValue || this._preInitValue);
		if (this._initOnConstruct) {
			this.observe(params);
		}
	}

	/**
	 * Function to remove the preInit property. Can be overridden if pre-init state is indicated some other way.
	 * @param {object} input Object that may contain a preInit property
	 */
	_scrubPreInit(input) {
		if (input && input.preInit) {
			const output = { ...input };
			delete output.preInit;
			return output;
		}
		return input;
	}

	/**
	 * Update the subject with state changes.
	 * @param {object} value Next value to be emitted to observers.
	 */
	_next(value) {
		this._subject.next(value);
	}

	/**
	 * Build the state data for a loading state.
	 * @param {object} data Any additional data to include in the object.
	 * @returns void
	 */
	_loading(data = {}) {
		this._next({
			...(this._scrubPreInit(this._subject.value) || {}),
			...data,
			loading: true,
			error: null,
		});
	}

	/**
	 * Build the state data for an error state.
	 * @param {Error|string} error Error that was thrown or encountered.
	 * @param {object} data Any additional data to include in the object.
	 */
	_error(error, data = {}) {
		this._next({
			...data,
			loading: false,
			error: error || true,
		});
	}

	/**
	 * Build the state for a successful response with data.
	 * @param {object} data Data to include in the object.
	 */
	_result(data = {}) {
		this._next({
			...data,
			loading: false,
			error: null,
		});
	}

	/**
	 * Override this to perform the fetch operation.
	 * @param {object} params Parameters
	 * @returns Promise Returns a promise bearing the results of the fetch or other such operation.
	 * @throws Error Throws an error if the result should be an error state.
	 */
	_fetch(params) {
		// Call this._loading() if you want load state during the fetch. It is not done automatically.
		this._loading();
		// Return a promise with the result
		return Promise.resolve({});
	}

	/**
	 * Override this if you need logic to determine when a fetch operation is necessary during an "observe" call.
	 * @param {object} params Parameters object
	 * @return boolean Whether or not a re-fetch should occur.
	 */
	_shouldUpdate(params) {
		return !!(this._subject.value.preInit || this._subject.value.error);
	}

	/**
	 * Perform a re-fetch and update (regardless of _shouldUpdate result).
	 * @param {params} params Parameters.
	 */
	update(params) {
		this._fetch(params)
			.then(result => this._result(result))
			.catch(error => this._error(error));
		return this.observable;
	}

	/**
	 * Return the observable for the API, initializing or updating it if necessary.
	 * @param {object} params Params.
	 */
	observe(params) {
		if (this._shouldUpdate(params)) {
			return this.update(params);
		}
		return this.observable;
	}

	/**
	 * The last known state.
	 */
	get value() {
		return this._subject.value;
	}

	/**
	 * Retrieves the Observable, but does not initialize or update.
	 */
	get observable() {
		return this._subject.observable;
	}
}

export default APISubject;
