import LocalizationStream from './LocalizationStream';

/**
 * Initialize and export a single global LocalizationStream.
 */
const localeStream = (global.localeStream = global.localeStream || new LocalizationStream());
const localeObservable = localeStream.observable;
const addMessagesLoader = localeStream.addMessagesLoader.bind(localeStream);
const setLocale = localeStream.setLocale.bind(localeStream);

export { localeObservable, addMessagesLoader, setLocale };
export default localeStream;
