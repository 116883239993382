export const LOADING = 1520; // 'Loading'
export const PLEASE_WAIT = 17955; // 'Please wait'

// export const BCC_FOOTER_COMPANY_NAME = 13126; // Barracuda Networks
export const BCC_FOOTER_ALL_RIGHTS_RESERVED = 13263; // All rights reserved.
export const BCC_FOOTER_PRIVACY_POLICY_LINK_TITLE = 13264; // Privacy Policy
export const BCC_FOOTER_TERMS_OF_SERVICE_LINK_TITLE = 27081; // Terms of Service

export const BCC_ACCOUNT_SWITCHER_FILTER_ACCOUNTS = 44776; // 'Filter Accounts';
export const BCC_ACCOUNT_SWITCHER_ACCOUNTS = 41697; // 'Accounts';
export const BCC_ACCOUNT_SWITCHER_ACCOUNT_COLON = 44777; // 'Account:';
export const BCC_ACCOUNT_SWITCHER_NO_ACCOUNTS_FOUND = 34285; // 'No accounts found.';
export const BCC_ACCOUNT_SWITCHER_MY_BILL = 44778; // 'My Bill';
export const BCC_ACCOUNT_SWITCHER_MANAGE_ACCOUNT = 44779; // 'Manage Accounts';
export const BCC_ACCOUNT_SWITCHER_DEFAULT = 1912; // 'Default';
export const BCC_ACCOUNT_SWITCHER_ERROR_MFA_REQUIRED = 31047; // 'This account requires Multi-Factor authentication in order to access it.';

export const BCC_SERVICE_SWITCHER_NO_PRODUCTS = 44780; // 'No products are associated with this account.';
export const BCC_SERVICE_SWITCHER_ADDITIONAL_PRODUCTS = 44781; // 'Additional Products';
export const BCC_SERVICE_SWITCHER_ACTIVATE_OR_START_TRIAL = 44782; // 'Activate purchases or start a trial for other Barracuda solutions.';
export const BCC_SERVICE_SWITCHER_COULD_NOT_SHOW = 44821; // 'There was a problem showing the product selector';

export const BCC_USER_MENU_MY_PROFILE = 15292; //'My Profile';
export const BCC_USER_MENU_LANGUAGE = 7518; // 'Language';
export const BCC_USER_MENU_COMMUNITY = 15411; // 'Community';
export const BCC_USER_MENU_SUPPORT = 5991; // 'Support';
export const BCC_USER_MENU_DOWNLOADS = 46220; // 'Downloads';
export const BCC_USER_MENU_SETTINGS = 7913; // 'Settings';
export const BCC_USER_MENU_SIGN_OUT = 11294; // 'Sign Out';
export const BCC_USER_MENU_SIGN_IN = 11295; // 'Sign In';
export const BCC_USER_MENU_COULD_NOT_SHOW = 44820; // 'There was an error contacting our authentication server.';

export const BCC_SIDEBAR_SEARCH_PLACEHOLDER_TITLE = 3549; // Search
export const BCC_SIDEBAR_TRIAL_LABEL = 22647; // Trial
export const BCC_SIDEBAR_EXPIRED_LABEL = 1179; // Expired

// used in bbs tree provider
export const BBS_DEFAULT_GROUP_NAME = 39849; // Main
