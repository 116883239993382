// @flow
import React from 'react';
import { render, unmountComponentAtNode } from 'react-dom';
import log from 'lib/util/log';

import Wrapper from 'lib/components/Wrapper';
import Config from 'lib/config';
import Events from 'lib/events';

// exported for tests
export class WrapperController {
	div: global.document.Element;
	opts: Object;
	static factory(...args) {
		return new WrapperController(...args);
	}
	constructor(opts: Object) {
		this.opts = opts;
	}
	render(): void {
		// everything is done in portals, so we create a div to render nothing in
		this.div = global.document.createElement('div');
		render(<Wrapper {...this.opts} />, this.div);
		// make sure our global styles get put somewhere
		global.document.head.appendChild(this.div);
	}
	cleanup(): void {
		unmountComponentAtNode(this.div);
		this.div.remove();
	}
}

/**
 * initializeWrapper
 *
 * @param {object} opts object
 */
export function initializeWrapper(opts: Object = {}) {
	// check for documented required items
	if (!opts.serviceId) throw log.error('Option `serviceId` is required.');
	if (!opts.serviceTitle) throw log.error('Option `serviceTitle` is required.');

	// pull out supported config items
	const {
		serviceId,
		serviceTitle,
		serviceCallout,
		sessionToken,
		sessionDestroyCallback,
		showSidebar,
		showNavigation,
		showAccountSwitcher,
		showActivityIndicator,
		navigationTabs,
		navigationHrefCallback,
		footerInfo,
		footerCustomLogo,
		footerCustomLogoLink,
		activityIndicatorObservable,
		sidebarTreeProvider,
		sidebarSelectedNode,
		sidebarFilterString,
		sidebarHrefCallback,
		...remainingOpts
	} = opts;

	Config.set({
		SERVICE_ID: serviceId,
		SERVICE_TITLE: serviceTitle,
		SERVICE_CALLOUT: serviceCallout,
		SESSION_TOKEN: sessionToken,
		SESSION_DESTROY_CALLBACK: sessionDestroyCallback,
		SHOW_SIDEBAR: showSidebar,
		SHOW_NAVIGATION: showNavigation,
		SHOW_ACCOUNT_SWITCHER: showAccountSwitcher,
		SHOW_ACTIVITY_INDICATOR: showActivityIndicator,
		NAVIGATION_TABS: navigationTabs,
		NAVIGATION_HREF_CALLBACK: navigationHrefCallback,
		BCC_FOOTER_INFO: footerInfo,
		BCC_FOOTER_CUSTOM_LOGO: footerCustomLogo,
		BCC_FOOTER_CUSTOM_LOGO_LINK: footerCustomLogoLink,
		ACTIVITY_INDICATOR_OBSERVABLE: activityIndicatorObservable,
		SIDEBAR_TREE_PROVIDER: sidebarTreeProvider,
		SIDEBAR_SELECTED_NODE: sidebarSelectedNode,
		SIDEBAR_FILTER_STRING: sidebarFilterString,
		SIDEBAR_HREF_CALLBACK: sidebarHrefCallback,
	});

	return WrapperController.factory(remainingOpts);
}

/* *** i18n API *** */

/**
 * Performs a full refresh of the device tree, re-calling all device listing APIs.
 * Usually used when a change is made server-side, for example to a devices's name or status.
 */
export function setLocale(locale: string): void {
	Events.trigger('_apiCallI18n', { name: 'setLocale', args: { locale } }, false, true);
}

/* *** Sidebar API *** */

/**
 * Performs a full refresh of the device tree, re-calling all device listing APIs.
 * Usually used when a change is made server-side, for example to a devices's name or status.
 */
export function refreshDevices(): void {
	Events.trigger('_apiCallSidebar', { name: 'refreshDevices', args: {} }, false, true);
}

/* *** Navigation API *** */

/**
 * Selects a tab, deselecting all others on its level, and deselecting
 * any tabs on lower levels. Fires a `navbarSelectionChange` event.
 *
 * @param {string} id the id of the tab to select
 */
export function selectTab(id: string): void {
	Events.trigger('_apiCallNavigation', { name: 'selectTab', args: { id } }, false, true);
}

/**
 * Updates a tab's data. Alternate method of setting `loading` or
 * `disabled` status from outside of a navbarSelectionChange event
 * handler (for example when using `react-router` and responding directly to URI changes).
 *
 * @param {string} id the id of the tab to select
 * @param {object} data the new tab strucutre
 */
export function updateTab(id: string, tabStructure: TabStructure): void {
	Events.trigger('_apiCallNavigation', { name: 'updateTab', args: { id, tabStructure } }, false, true);
}

/**
 * Hides a tab, identified by the id given it initially.
 *
 * @param {string} id the id of the tab to hide
 */
export function hideTab(id: string): void {
	Events.trigger('_apiCallNavigation', { name: 'hideTab', args: { id } }, false, true);
}

/**
 * Hides one or more tabs, identified by the id given them initially.
 *
 * @param {Array<string>} ids the ids of the tabs to hide
 */
export function hideTabs(ids: Array<string>): void {
	Events.trigger('_apiCallNavigation', { name: 'hideTabs', args: { ids } }, false, true);
}

/**
 * Shows a tab, identified by the id given it initially.
 *
 * @param {string} id the id of the tab to show
 */
export function showTab(id: string): void {
	Events.trigger('_apiCallNavigation', { name: 'showTab', args: { id } }, false, true);
}

/**
 * Shows one or more tabs, identified by the id given them initially.
 *
 * @param {Array<string>} ids the ids of the tabs to show
 */
export function showTabs(ids: Array<string>): void {
	Events.trigger('_apiCallNavigation', { name: 'showTabs', args: { ids } }, false, true);
}

export default {
	initializeWrapper,
	setLocale,
	refreshDevices,
	selectTab,
	updateTab,
	hideTab,
	hideTabs,
	showTab,
	showTabs,
};
