export function info(...args) {
	console.info(...args);
}

export function debug(...args) {
	console.debug(...args);
}

export function warn(...args) {
	console.warn(...args);
}

export function error(...args) {
	console.error(...args);
	return new Error(...args);
}

export default { info, debug, warn, error };
