import log from '../util/log';
import localeStream from './localeStream';
import { translate } from './util';

/**
 * Translates a LID into a string, based on the current locale
 *
 * THIS FUNCTION DOES NOT REFLECT LOCALE CHANGES MADE AFTER IT RUNS. This may present fallback locations if,
 * for instance, the messages file for the locale is not loaded, or if the user changes the language setting.
 * You should probably be using a component instead.
 *
 * @param   {number} lid   The lid to identify the message to translate
 * @param   {object|array} variables  A 1-based array of strings or
 *                         object of keys and strings to replace with __ around them
 * @param   {function}     postFn  A function to process the string after translation
 * @returns {string}       The translated string, or empty string if no translation was found.
 */
export default function translateLid(lid, variables = [], postFn) {
	try {
		return translate(localeStream.value, lid, variables, postFn);
	} catch (error) {
		if (error.type === 'MISSING_LID') {
			localeStream.reportMissingLid(lid, error);
			return '';
		} else throw error;
	}
}
