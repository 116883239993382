// @flow
import BehaviorSubject from '../util/BehaviorSubject';
import { from, combineLatest } from 'light-observable/observable';
import { map } from 'light-observable/operators';
import { MANAGE_ALL_ACCOUNTS_ID } from '../constants';
import { fetchDedupe } from 'fetch-dedupe';
import { compose, withProps, mapPropsStream } from 'recompose';
import parseXML from 'lib/util/parseXML';
import Cookie from 'js-cookie';
import Config from 'lib/config';
import i18n from 'lib/i18n';
import { BCC_ACCOUNT_SWITCHER_ERROR_MFA_REQUIRED } from 'lib/lids';

const BCC_SOAP_API_ERROR_MFA_REQUIRED = 116;

export type CurrentAccountState = {|
	optimistic: {|
		id?: number,
		allAccounts?: boolean,
	|},
	loading: boolean,
	error: boolean | string,
	id?: number,
	allAccounts?: boolean,
	is_msp: boolean,
	has_admin: boolean,
	has_billing: boolean,
|};

let currentAccount: CurrentAccountState = { optimistic: {} };

// Cast boolean-indicating strings from the API
const toBool = value => !(!value || value === 'false' || value === '0');

const currentAccountBehaviorSubject = new BehaviorSubject(currentAccount);
const observable = currentAccountBehaviorSubject.observable;

const reset = () => {
	currentAccount = { optimistic: {} };
};

const getCurrentAccountState = () => ({ ...currentAccount });

const setCurrentAccountCookies = (id, allAccounts) => {
	Cookie.set('current_account_all', allAccounts ? 1 : 0, {
		path: '/',
		domain: Config.COOKIE_DOMAIN,
		secure: true,
	});

	Cookie.set('current_account', id, {
		path: '/',
		domain: Config.COOKIE_DOMAIN,
		secure: true,
	});
};

const setCurrentAccount = (
	id: number,
	allAccounts: boolean = false,
	is_msp: boolean,
	has_admin: boolean,
	has_billing: boolean,
) => {
	currentAccount = {
		loading: false,
		error: false,
		optimistic: {},
		id,
		allAccounts,
		is_msp,
		has_admin,
		has_billing,
	};
	currentAccountBehaviorSubject.next({ ...currentAccount });
	setCurrentAccountCookies(id, allAccounts);
};

const setError = (id: number, allAccounts: boolean, error: string) => {
	currentAccountBehaviorSubject.next({
		optimistic: {
			id,
			allAccounts,
		},
		id: currentAccount.id || null,
		allAccounts: !!currentAccount.allAccounts,
		loading: false,
		error,
	});
};

// Parameters are optimistic values
const setLoading = (id: number = null, allAccounts: boolean = false) => {
	currentAccount = {
		loading: true,
		error: false,
		optimistic: {
			id,
			allAccounts,
		},
		id: currentAccount.id || null,
		allAccounts: !!currentAccount.allAccounts,
	};
	currentAccountBehaviorSubject.next({ ...currentAccount });
};

const switchAccountSOAP = (accountID: number, allAccounts: boolean = false) => {
	const sessionToken = Config.SESSION_TOKEN || '';
	const effectiveAccountId = allAccounts ? MANAGE_ALL_ACCOUNTS_ID : accountID;

	return fetchDedupe(
		Config.BCC_SOAP_API_URL,
		{
			method: 'post',
			mode: 'cors',
			credentials: 'include',
			headers: {
				'Content-Type': 'text/xml; charset=utf-8',
				SOAPAction: 'switchAccount',
			},
			body: `
			<?xml version="1.0" encoding="utf-8"?>
			<x:Envelope xmlns:x="http://schemas.xmlsoap.org/soap/envelope/" xmlns:urn="urn:BarracudaCloudAPI">
				<x:Header/>
				<x:Body>
					<urn:switchAccountRequest>
						<urn:auth_token>${sessionToken}</urn:auth_token>
						<urn:account_id>${effectiveAccountId}</urn:account_id>
					</urn:switchAccountRequest>
				</x:Body>
			</x:Envelope>
		`.trim(),
		},
		{
			responseType: 'text',
		},
	)
		.then(({ data: xml }) => parseXML(xml))
		.then(
			result =>
				result['SOAP-ENV:Envelope']['SOAP-ENV:Body']['ns1:switchAccountResponse'] || {
					error: true,
				},
		)
		.catch((e) => {
			console.warn('An error occurred:', e);
			throw e;
		});
};

const switchAccount = (accountID: number, allAccounts: boolean = false) => {
	setLoading(accountID, allAccounts);
	return switchAccountSOAP(accountID, allAccounts)
		.then(result => {
			if (!result.success) {
				let errorText;

				if (result.error_code == BCC_SOAP_API_ERROR_MFA_REQUIRED) {
					errorText = i18n.translateLid(BCC_ACCOUNT_SWITCHER_ERROR_MFA_REQUIRED);
				} else {
					errorText = 'Error switching account';
				}

				setError(accountID, allAccounts, errorText);
				throw new Error(errorText);
			}

			const isMSP = result && toBool(result.is_msp);
			const hasAdmin = result && toBool(result.has_admin);
			const hasBilling = result && toBool(result.has_billing);

			setCurrentAccount(accountID, allAccounts, isMSP, hasAdmin, hasBilling);
		})
		.catch(e => {
			// set account back to what it was
			setError(currentAccount.id, currentAccount.allAccounts, false);
			// rethrow so it can be handled in the UI
			throw e;
		});
};

export const withCurrentAccount = compose(
	mapPropsStream(propsObs =>
		combineLatest(from(propsObs), observable).pipe(
			map(([props, current]) => ({ ...props, currentAccount: current })),
		),
	),
	withProps(() => ({ switchAccount })),
);

// For consistency, export observe/update functions similar to other APISubject-based observables.
export const observe = () => observable;
export const update = ({ id, allAccounts }) => switchAccount(id, allAccounts);

export { observable, switchAccount, getCurrentAccountState, reset };
export default observable;
