import React from 'react';

const withCss = (...csses) => WrappedComponent => props => (
	<>
		<WrappedComponent {...props} />
		{<style>{csses.map(c => c.toString())}</style>}
	</>
);

export default withCss;
