// @flow

import React from 'react';
import { unmountComponentAtNode } from 'react-dom';
import { withConfigProps } from 'lib/config';

// Workaround for: https://github.com/developit/preact-compat/issues/501
// when that bug is fixed, createPortal can come from 'react-dom'
import { createPortal } from 'lib/util/preactCreatePortal';

import log from '../util/log';
import css, { locals as styles } from './Wrapper.scss';
import withCss from './util-hoc/withCss';

// the fragment notation is required to work in Preact
// probably something about only supporting a single child in a portal
const Portal = ({ element, children }) => createPortal(<>{children}</>, element);

import LazyAccountSwitcher from 'lib/components/LazyAccountSwitcher';
import LazyHeader from 'lib/components/LazyHeader';
import LazyNavigation from 'lib/components/LazyNavigation';
import LazySidebar from 'lib/components/LazySidebar';
import LazyFooter from 'lib/components/LazyFooter';

// Uncomment these to compile them statically into the main bundle
// import AccountSwitcher from 'lib/components/AccountSwitcher';
// import Header from 'lib/components/Header';
// import Navigation from 'lib/components/Navigation';
// import Sidebar from 'lib/components/Sidebar';
// import Footer from 'lib/components/Footer';

const isElement = el => el instanceof global.Element;
const isString = str => typeof str === 'string' || str instanceof global.String;
// http://stackoverflow.com/questions/105034/how-to-create-a-guid-uuid-in-javascript
const quickHash = () =>
	Math.random()
		.toString(36)
		.substring(2, 15);

function resolveContainerProp(el) {
	// if el is an element, just return it
	return isElement(el)
		? el
		: // if el is a string, resolve it
		isString(el)
		? global.document.querySelector(el)
		: // otherwise return null
		  null;
}

const generateDOMElement = (name: string, insertAtEnd: boolean = false) => {
	// div.id = `chuiContainer-${name}-${quickHash()}`;
	const doc = global.document;
	const id = `chui-generated-container-${name}-${quickHash()}`;

	const existingElement = doc.getElementById(id);
	if (existingElement) {
		return existingElement;
	}

	const div = doc.createElement('div');
	div.id = id;

	if (insertAtEnd) {
		doc.body.appendChild(div);
	} else {
		doc.body.insertBefore(div, doc.body.firstChild);
	}

	return div;
};

const cleanUpDOMElement = (element, alsoRemoveElementItself = false) => {
	if (element) {
		// clean up element's children
		unmountComponentAtNode(element);
		// remove element if we created it
		if (alsoRemoveElementItself) {
			element.remove();
		}
	}
};

import { compose, setDisplayName, lifecycle, defaultProps } from 'recompose';

const withElementCreator = compose(
	lifecycle({
		componentDidMount() {
			const { headerContainer, sidebarContainer, footerContainer } = this.props;
			this.setState({
				headerContainer:
					(this.state && this.state.headerContainer) ||
					resolveContainerProp(headerContainer) ||
					generateDOMElement('header'),
				sidebarContainer: (this.state && this.state.sidebarContainer) || resolveContainerProp(sidebarContainer),
				footerContainer:
					(this.state && this.state.footerContainer) ||
					resolveContainerProp(footerContainer) ||
					generateDOMElement('footer', true),
			});
		},
		componentWillUnmount() {
			const { headerContainer, sidebarContainer, footerContainer } = this.props;
			// we have to clean these up after the portals themselves are deregistered
			cleanUpDOMElement(this.state.headerContainer, !headerContainer);
			cleanUpDOMElement(this.state.sidebarContainer, !sidebarContainer);
			cleanUpDOMElement(this.state.footerContainer, !footerContainer);

			this.setState({
				headerContainer: null,
				sidebarContainer: null,
				footerContainer: null,
			});
		},
	}),
);

const WrapperHOC = compose(
	setDisplayName('WrapperWithHOC'),
	withConfigProps(({ SHOW_SIDEBAR, SHOW_NAVIGATION, SHOW_ACCOUNT_SWITCHER }) => ({
		showSidebar: SHOW_SIDEBAR,
		showNavigation: SHOW_NAVIGATION,
		showAccountSwitcher: SHOW_ACCOUNT_SWITCHER,
	})),
	defaultProps({
		showSidebar: false,
		showNavigation: false,
		showAccountSwitcher: true,
	}),
	withElementCreator,
	withCss(css),
);

const footerPositioningClasses = {
	absolute: styles.absolute,
	fixed: styles.fixed,
	manual: '',
};

const Wrapper = ({
	// these are only required if intended to be rendered
	headerContainer,
	sidebarContainer,
	footerContainer,

	footerPositioning = 'absolute',

	// components are required if they are to be rendered
	accountSwitcherComponent: AccountSwitcherComponent = LazyAccountSwitcher,
	headerComponent: HeaderComponent = LazyHeader,
	navigationComponent: NavigationComponent = LazyNavigation,
	sidebarComponent: SidebarComponent = LazySidebar,
	footerComponent: FooterComponent = LazyFooter,

	// from config (defaults in WrapperHOC)
	showSidebar,
	showNavigation,
	showAccountSwitcher,
}) => {
	const footerPositioningClass = footerPositioningClasses[footerPositioning] || footerPositioningClasses.manual;

	return (
		<>
			{/* we don't put the header in a portal; it's the main thing we render*/}
			{isElement(headerContainer) && (
				<Portal element={headerContainer}>
					{[styles.placeholder, `${styles.header} qa-visible`].map((className, idx) => (
						<div className={className} key={idx}>
							{showAccountSwitcher && <AccountSwitcherComponent hideWhenLoading={true} hideWhenError={true} />}
							<HeaderComponent />
							{showNavigation && <NavigationComponent />}
						</div>
					))}
				</Portal>
			)}
			{/* we put the footer in a portal because it has to be at the end */}
			{isElement(footerContainer) && (
				<Portal element={footerContainer}>
					{[styles.placeholder, `${styles.footer} ${footerPositioningClass} qa-visible`].map((className, idx) => (
						<div className={className} key={idx}>
							<FooterComponent />
						</div>
					))}
				</Portal>
			)}
			{/* Sidebar is only used for a few products, and is off by default */}
			{showSidebar && SidebarComponent && isElement(sidebarContainer) && (
				<Portal element={sidebarContainer}>
					{[`${styles.sidebar} qa-visible`].map((className, idx) => (
						<div className={className} key={idx}>
							<SidebarComponent />
						</div>
					))}
				</Portal>
			)}
		</>
	);
};

export default WrapperHOC(Wrapper);
export { WrapperHOC, Wrapper };
