exports = module.exports = require("../../node_modules/css-loader/dist/runtime/api.js")(true);
// Imports
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400);", ""]);

// Module
exports.push([module.id, "", "",{"version":3,"sources":[],"names":[],"mappings":"","file":"base.scss"}]);

// Exports
exports.locals = {
	"breakSmall": "600px",
	"wrapperBaseZIndex": "1000",
	"headerIconSize": "24px"
};