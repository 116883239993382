import BehaviorSubject from 'lib/util/BehaviorSubject';
import log from 'lib/util/log';
import resolveAll from 'lib/util/resolveAllPromises';

const BASE_MESSAGES_LOCALE = 'en_US';
const QA_LOCALE = 'en_QA';

class LocalizationStream {
	_currentLocale = BASE_MESSAGES_LOCALE;
	_stateStream = null;
	_baseMessages = null;
	_loaders = [];

	// members should be of the form { lid: Number, error: Error }
	_errorQueue = [];

	constructor(locale = BASE_MESSAGES_LOCALE, baseLoaders = []) {
		this._loaders = [...baseLoaders];
		this._stateStream = new BehaviorSubject({
			locale,
			messages: {},
			preInit: true,
		});
	}

	/**
	 * Set the current locale messages to the message-set provided and emit an update.
	 * @param {object} messages LID:message object from _loadMessages() or _getMessages()
	 * @param {string} locale Locale code for the messages
	 * @returns {object} Returns the object passed in.
	 */
	_setCurrentLocaleMessages(messages, locale, clearPreInit) {
		const next = {
			...this._stateStream.value,
			locale,
			messages,
		};
		if (clearPreInit) {
			delete next.preInit;
		}
		this._currentLocale = locale;
		this._stateStream.next(next);
		return messages;
	}

	/**
	 * Adds a missing lid error to a queue, to be re-checked after all loaders have finished.
	 *
	 * @param {number} lid The lid that was searched for, so it can be re-checked.
	 * @param {object} error The error to re-throw if the lid is stil not found.
	 */
	reportMissingLid(lid, error) {
		// log.debug('Missing lid reported, postponing error', lid, error);
		this._errorQueue.push({ lid, error });
	}

	/**
	 * Processes the missing lid error queue, re-throwing any lids that are still missing.
	 */
	_processErrorQueue() {
		// log.debug('calling _processErrorQueue containing', this._errorQueue.length, 'errors');
		const messages = this._getCurrentLocaleMessages();

		while (this._errorQueue.length) {
			const { lid, error } = this._errorQueue.shift();
			if (messages[lid] === undefined) {
				log.warn(error);
			}
		}
	}

	/**
	 * Get the current locale's messages.
	 * @returns {object} LID:message object
	 */
	_getCurrentLocaleMessages() {
		return this._stateStream.value.messages;
	}

	/**
	 * Execute loader functions to load strings for the given locale.
	 * @param {string} locale Locale to pass to loaders
	 * @param {array<function>|null} loaders Loaders to run. If omitted or null, loads from all of this._loaders.
	 * @param {object} append If provided, results are combined with the given object.
	 * @returns {Promise<object>} Returns a Promise yielding a LID:translation object combined from all loaders.
	 */
	_loadMessages(locale, loaders = null, appendTo = {}) {
		// Run all loaders and compile the successful results into an object
		return resolveAll((loaders || this._loaders).map(fn => fn(locale))).then(results =>
			results.filter(r => r.success).reduce((all, curr) => ({ ...all, ...curr.result }), appendTo),
		);
	}

	/**
	 * Gets messages either from local storage or loaders and returns a Promise yielding an object with the
	 * combined results from all successful loaders. This is NOT used in loading the base messages.
	 * @param {string} locale Locale code (passed to loaders)
	 * @param {boolean} forceRefresh If false, messages will not be reloaded if either the current or base locale
	 *                               matches the requested locale. Instead, messages will be taken from the
	 *                               relevant local store.
	 * @returns {Promise<object>} A promise yielding the LID:translation object for the requested locale
	 */
	_getMessages(locale, forceRefresh = false) {
		// load base messages first, then load the locale's.
		return this._updateBaseMessages().then(() => {
			// We may not need to load messages...
			if (!forceRefresh) {
				// If the locale has not changed, return the current information
				if (locale === this._stateStream.value.locale) {
					return Promise.resolve(this._getCurrentLocaleMessages());
				}

				// If requesting the base messages locale, return a copy of _baseMessages instead of loading again.
				if (locale === BASE_MESSAGES_LOCALE) {
					return Promise.resolve({ ...this._baseMessages });
				}
			}

			// If requesting the QA locale, generate it from the base locale.
			if (locale === QA_LOCALE) {
				return Promise.resolve(this._getQAMessages());
			}

			// Load the new locale, and combine it with base messages.
			return this._loadMessages(locale).then(messages => ({ ...this._baseMessages, ...messages }));
		});
	}

	/**
	 * Create QA messages from the base locale. This assumes that this._baseMessages has already been populated.
	 * @returns {object} LID:translation object with QA messages. Does NOT return a Promise.
	 */
	_getQAMessages() {
		return Object.keys(this._baseMessages).reduce((msgs, key) => {
			msgs[key] = `${this._baseMessages[key]} [${key}]`;
			return msgs;
		}, {});
	}

	/**
	 * Load base messages (which serve as fallbacks in the case of missing messages) into this._baseMessages
	 * @param {array<function>|null} loaders Specific loaders to call. If omitted, all of this._loaders will be loaded.
	 * @param {boolean} append If true, the existing base messages will not be removed.
	 */
	_updateBaseMessages(loaders, append = false) {
		// Load BASE_MESSAGES_LOCALE messages and apply the result to this._baseMessages
		return this._loadMessages(BASE_MESSAGES_LOCALE, loaders, append ? this._baseMessages || {} : {}).then(
			newBaseMessages => {
				this._baseMessages = { ...this._baseMessages, ...newBaseMessages };
			},
		);
	}

	/**
	 * Add a new loader to be run after any existing loaders, then run it and update strings.
	 * @param {function} loader The new loader.
	 */
	addMessagesLoader(loader) {
		// check (by function name or obj sameness) for if this loader was already added; replace it if so
		const existingLoaderIndex = this._loaders.findIndex(fn => {
			if (fn.name || loader.name) {
				return fn.name === loader.name;
			} else {
				return fn === loader;
			}
		});

		if (existingLoaderIndex !== -1) {
			this._loaders[existingLoaderIndex] = loader;
		} else {
			this._loaders.push(loader);
		}

		return this.setLocale(this._currentLocale, true);
	}

	/**
	 * Reset to a new set of loaders.
	 *
	 * Used only for testing.
	 *
	 * @param {array<function>} loaders Array of loaders.
	 */
	setMessagesLoaders(loaders = []) {
		this._loaders = [...loaders];
		return (
			// Load base messages (from this._loaders) and apply to a fresh baseMessages
			this._updateBaseMessages(null, {})
				// Force-refresh current locale messages
				.then(() => {
					return this.setLocale(this._currentLocale, true);
				})
		);
	}

	/**
	 * Read-only value getter.
	 * @returns {object} Object with locale and messages.
	 */
	get value() {
		return { ...this._stateStream.value };
	}

	/**
	 * Read-only observable getter.
	 * @returns {Observable} Observable that emits all changes to the current locale messages.
	 */
	get observable() {
		return this._stateStream.observable;
	}

	/**
	 * Change/set locale and load messages if required or requested (with forceRefresh)
	 * @param {string} locale Locale to change to.
	 * @param {boolean} forceRefresh If true, loaders are always re-run. If false and the locale selected is either the
	 *                               same as the current one or the base locale, messages are set from the local store.
	 * @return {Promise<object>} Returns a promise yielding the locale-information object.
	 */
	setLocale(locale, forceRefresh = false) {
		// Handle message loading in various situations:
		return this._getMessages(locale, forceRefresh || !!this._stateStream.value.preInit)
			.then(messages => {
				this._setCurrentLocaleMessages(messages, locale, true);
				return this._stateStream.value;
			})
			.catch(err => {
				log.error(`Failed to change language to ${locale}: ${err.message || err}`);
				this._stateStream.next(this._stateStream.value);
				throw err;
			})
			.then(this._processErrorQueue.bind(this));
	}
}

export default LocalizationStream;
