/**
 * Combine an array of objects, by flattening or named sub-objects.
 * @param {array<object>} arr Array of objects to combine.
 * @param {array<string|undefined>} keys Array of keys to use for each sub-object. If an element is undefined, that object is just flattened in.
 * @returns {object} A combined object.
 */
export const combineArrayOfObjects = (arr, keys = []) => arr.reduce(
	(out, obj, idx) => ({
		...out,
		...(keys[idx] === undefined ? obj : { [keys[idx]]: obj }),
	}), {}
);

/**
 * Generate a function to run combineArrayOfObjects with a given keys spec.
 * @param {*} keys Array of keys to use for each sub-object. If an element is undefined, that object is just flattened in.
 * @returns {function} A function that combines the arrays given in the manner specified.
 */
export const combineArrayOfObjectsFunction = keys => arr => combineArrayOfObjects(arr, keys);
