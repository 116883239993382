import log from 'lib/util/log';

// this is a global event registry, to allow
// locally-loaded i18n's to listen to global CHUI.API.setLocale()
const listenersByEventName = (global.listenersByEventName = global.listenersByEventName || {
	// sidebarVisibilityChange: [], // OLD
	// pageLayoutReflow: [], // OLD
	navbarSelectionChange: [], // NAVIGATION
	deviceSelectionWillChange: [], // SIDEBAR
	deviceSelectionChange: [], // SIDEBAR
	accountSelectionChange: [], // ACCOUNTSIWTCHER
	// sidebarMounted: [], // SIDEBAR / OLD
	// headerMounted: [], // HEADER / OLD
	// never actually triggered outside of unit tests
	_testEvent: [],
	// internally used
	// globalResize: [], // OLD
	// globalScroll: [], // OLD
	_apiCallNavigation: [],
	_apiCallSidebar: [],
	_apiCallI18n: [],
});

const eventQueueByEventName = {};

/**
 * Adds a listener to the given HUI event
 * @param {string}   eventName The name of the event
 * @param {Function} callback  The function to be called when the event triggers
 * @param {boolean}   internal Whether the event handler should survive a simple .clear()
 */
function addHuiEventListener(eventName, callback, internal: ?boolean = false) {
	if (!listenersByEventName[eventName]) {
		return;
	}

	// mark this so .clear(false) doesn't remove it
	callback._internal = internal;

	listenersByEventName[eventName].push(callback);

	emptyQueueForEventName(eventName);
}

/**
 * Removes a listener from the given HUI event
 * @param {string}   eventName The name of the event
 * @param {Function} callback  The function removed as an event handler
 */
function removeHuiEventListener(eventName, callback) {
	const listeners = listenersByEventName[eventName];
	if (!listeners) {
		return;
	}

	const listenerIdx = listeners.indexOf(callback);

	if (listenerIdx !== -1) {
		listeners.splice(listenerIdx, 1);
	}
}

/**
 * Listens to an event once, then removes the listener.
 * @param {string}   eventName The name of the event
 * @param {Function} callback  The function to be called when the event triggers
 */
function listenToHuiEventOnce(eventName, callback) {
	const oneTimeCallback = (...args) => {
		// Run the original function
		callback(...args);
		// Run removal code of the listener.
		removeHuiEventListener(eventName, oneTimeCallback);
	};

	addHuiEventListener(eventName, oneTimeCallback);
}

/**
 * Trigger a HUI event
 * @param   {string} eventName The name of the event to trigger
 * @param   {Object} data      Data to pass to the event listeners
 * @param   {boolean} preventable Whether the event can be prevented
 * @param   {boolean} queue Whether the event should be queued to fire when a future listener is added
 * @return  {Object} an object with return information
 * // TODO: provide dev-users with a more accessible reference for available events
 */
function triggerHuiEvent(eventName, data, preventable: ?boolean = false, queue: ?boolean = false) {
	const listeners = listenersByEventName[eventName];
	const result = { defaultPrevented: false };

	if (listeners && listeners.length) {
		if (preventable) {
			data.preventDefault = () => {
				result.defaultPrevented = true;
			};
		}
		listeners.forEach(listener => {
			if (typeof listener === 'function') {
				try {
					listener(data);
				} catch (e) {
					log.error(`HUI: Error in ${eventName} handler:`, e);
				}
			}
		});
	} else if (queue) {
		if (!eventQueueByEventName[eventName]) {
			eventQueueByEventName[eventName] = [];
		}
		eventQueueByEventName[eventName].push({ eventName, data, preventable });
	}

	return result;
}

function emptyQueueForEventName(eventName) {
	if (eventQueueByEventName[eventName]) {
		while (eventQueueByEventName[eventName].length) {
			const event = eventQueueByEventName[eventName].shift();
			triggerHuiEvent(event.eventName, event.data, event.preventable);
		}
	}
}

/**
 * Removes all listeners from all HUI events
 * @param {boolean}   includeInternal whether to clear all handlers
 */
function removeAllHuiEventListeners(includeInternal: ?boolean = false) {
	Object.entries(listenersByEventName).forEach(([eventName, callbacks]) => {
		listenersByEventName[eventName] = callbacks.filter(callback => {
			return callback._internal && !includeInternal;
		});
	});
}

const noop = () => {};

export default {
	init: noop, // was bindGlobalEventProxies
	addEventListener: addHuiEventListener,
	removeEventListener: removeHuiEventListener,
	listenToOnce: listenToHuiEventOnce,
	trigger: triggerHuiEvent,
	clear: removeAllHuiEventListeners,
};
