import ConfigStore, { withConfigStore } from 'lib/observables/configStore';
import { compose, withProps } from 'recompose';

const config = new ConfigStore({ __singleton: true });
const withConfigProps = fn =>
	compose(
		withConfigStore(config),
		withProps(fn)
	);

export default config;
export { withConfigProps };
