/* global window */
// @flow

import React from 'react';
import PropTypes from 'prop-types';
import Popover from 'react-tiny-popover';

import css, { locals as styles } from './MenuPopover.scss';
import withCss from './util-hoc/withCss';
import variables from '../base.scss';

const wrapperBaseZIndex = parseInt(variables.wrapperBaseZIndex, 10);

const MenuPopover = withCss(css)(({ side, ...props }) => (
	<Popover
		transitionDuration={0}
		containerClassName={`${props.containerClassName || ''}  ${styles.menuPopover} ${props.boxShadowContentOnly &&
			styles.boxShadowContentOnly}`}
		padding={0}
		disableReposition
		contentLocation={params => {
			const { targetRect, popoverRect } = params;

			// Horizontal position is based on whether the "side" prop is "right"
			// (or "left", by default)
			const left = side === 'right' ? targetRect.left + targetRect.width - popoverRect.width : targetRect.left;
			const top = targetRect.top;

			return { left, top };
		}}
		containerStyle={{
			maxHeight: '80vh',
			overflowY: 'auto',
			zIndex: wrapperBaseZIndex,
		}}
		{...props}
	/>
));

MenuPopover.propTypes = {
	...Popover.propTypes,
	side: PropTypes.oneOf(['left', 'right']),
};

export default MenuPopover;
