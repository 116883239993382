import { compose, mapPropsStream } from 'recompose';
import { from, combineLatest, merge } from 'light-observable/observable';
import { map, switchMap, scan } from 'light-observable/operators';
import { observe as getIsAuthenticatedObservable } from 'lib/observables/isAuthenticated';
import { observe as getCurrentAccountObservable } from 'lib/observables/currentAccount';
import getFirstDefined from 'lib/util/getFirstDefined';

/**
 * Generates an Observable bearing a simplified auth object combining the output of currentAccount with the (switched)
 * account ID from currentAccount, if one exists.
 * @param {number|string} serviceId
 */
export const observe = ({ serviceId }) => {
	return merge(getIsAuthenticatedObservable({ serviceId }), getCurrentAccountObservable()).pipe(
		scan(
			(prev, next) => ({
				...prev,
				...next,
				account_id: getFirstDefined(next.id, next.account_id, prev.account_id),
			}),
			{},
		),
	);
};

/**
 * Given a serviceId prop, output the values from the Observable returned above as props. Output props are flattened.
 */
export const withAuth = compose(
	mapPropsStream(props$ => {
		props$ = from(props$);
		return combineLatest(props$, props$.pipe(switchMap(({ serviceId }) => observe({ serviceId })))).pipe(
			map(([props, auth]) => ({ ...props, auth })),
		);
	}),
);

export default observe;
