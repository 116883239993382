import React from 'react';
import PropTypes from 'prop-types';
import ProvideLocalizedStrings from './ProvideLocalizedStrings';

const LocalizedImgAlt = ({
	Component = 'img',
	applyAltTo = ['alt', 'title'],
	lid,
	variables,
	postFn,
	...passProps
}) => {
	const lidMap = applyAltTo.reduce((attrs, attr) => ({ ...attrs, [attr]: variables ? { lid, variables } : lid }), {});
	const render = props => <Component {...props} {...passProps} />;
	return <ProvideLocalizedStrings {...{ render, lidMap, postFn }} />;
};

LocalizedImgAlt.propTypes = {
	lid: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
	variables: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	postFn: PropTypes.func,
	applyAltTo: PropTypes.arrayOf(PropTypes.string),
	Component: PropTypes.oneOf([PropTypes.func, PropTypes.string]),
};
LocalizedImgAlt.displayName = 'LocalizedImgAlt';

export default LocalizedImgAlt;
