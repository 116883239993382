import log from 'lib/util/log';
import React from 'react';
import PropTypes from 'prop-types';
import { sanitizeVariables, translate } from './util';
import withCurrentLocale from './withCurrentLocale';

/**
 * Component that generates a <span> with the localized string value for a given LID.
 * @param {number|string} lid The LID to translate.
 * @param {array|object} variables Any variables used by the translation.
 * @param {boolean} html HTML used in the translation should be rendered.
 * @param {boolean} unsafeVariables When html is true, setting unsafeVariables will sanitize variables before output
 *                                  to guard against injection attacks.
 * @param {array} exceptSafeVariables The variables named in this array will not be sanitized if unsafeVariables is set.
 *                                    Note that for variables arrays, the values ARE 1-indexed, just like in the template strings.
 * @param {boolean} postFn A post-processing function run on the resulting text. Should accept and return a string.
 */

const LocalizedStringBase = ({
	lid,
	variables: variablesProp,
	postFn,
	html,
	unsafeVariables,
	exceptSafeVariables,
	currentLocaleData,
	reportMissingLid,
	Component = 'span',
}) => {
	let translatedString;
	let variables = variablesProp;

	if (html && unsafeVariables) {
		variables = sanitizeVariables(variables, exceptSafeVariables);
	}

	try {
		translatedString = translate(currentLocaleData, lid, variables, postFn);
	} catch (error) {
		if (error.type === 'MISSING_LID') {
			reportMissingLid(lid, error);
		} else {
			throw error;
		}
		translatedString = '';
	}

	return html ? (
		<Component dangerouslySetInnerHTML={{ __html: translatedString }} />
	) : (
		<Component>{translatedString}</Component>
	);
};

LocalizedStringBase.displayName = 'LocalizedStringBase';
LocalizedStringBase.propTypes = {
	lid: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
	variables: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	html: PropTypes.bool,
	unsafeVariables: PropTypes.bool,
	exceptSafeVariables: PropTypes.array,
	postFn: PropTypes.func,
	showPreInit: PropTypes.bool,
	Component: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
};

LocalizedStringBase.defaultProps = {
	variables: [],
	showPreInit: false,
};

const LocalizedString = withCurrentLocale(LocalizedStringBase);
LocalizedString.displayName = 'LocalizedString';

export default LocalizedString;
