export const BCC_FOOTER_PRIVACY_URL = 'http://barracuda.com/privacy';
export const BCC_FOOTER_TERMS_URL = 'http://barracuda.com/terms';

export const MANAGE_ALL_ACCOUNTS_ID = '-1';
export const DEFAULT_BCC_SERVICE_ID = '100';

export const BASE_MESSAGES_LOCALE = 'en_US';
export const QA_LOCALE = 'en_QA';

export const SIDEBAR_NODE_TYPE_GROUP = 'group';
export const SIDEBAR_NODE_TYPE_BUTTON = 'button';
export const SIDEBAR_NODE_TYPE_DEVICE = 'device';

export const SIDEBAR_NODE_TYPES = [
	// provided in order, for sorting:
	SIDEBAR_NODE_TYPE_DEVICE,
	SIDEBAR_NODE_TYPE_GROUP,
	SIDEBAR_NODE_TYPE_BUTTON,
];
