import { mapPropsStream, withProps, compose } from 'recompose';
import localeStream from './localeStream';
import { filter, map } from 'light-observable/operators';
import { from, combineLatest } from 'light-observable/observable';

const withCurrentLocale = compose(
	mapPropsStream(props$ =>
		combineLatest(from(props$), localeStream.observable.pipe(filter(val => !val.preInit))).pipe(
			map(([props, currentLocaleData]) => ({
				...props,
				currentLocale: currentLocaleData.locale,
				currentLocaleData,
			})),
		),
	),
	withProps(() => ({
		setLocale: localeStream.setLocale.bind(localeStream),
		reportMissingLid: localeStream.reportMissingLid.bind(localeStream),
	})),
);

export default withCurrentLocale;
